<template>
  <div class="list">
    <div class="birth"></div>
    <div class="OneyearOld">
      <!-- 4/6 -->
      <div class="OneyearOld_flex" @click="showPopup">
        <p>点亮孕期备产礼</p>
        <img class="gift" :src="gift" alt="" />
        <p>{{ menuBox }}</p>
      </div>
    </div>
    <div class="TwoyearOld"></div>
    <div class="ThreeyearOld"></div>
    <div class="FouryearOld">
      <!-- 4/7 -->
      <div class="FouryearOld_flex" @click="showPopupList">
        <p>点亮一岁生日礼</p>
        <img class="gift" :src="gifts" alt="" />
        <p>{{ Unlock }}</p>
      </div>
    </div>
    <div class="FiveyearOld"></div>
    <div class="SixyearOld"></div>
    <div class="SevenyearOld">
      <!-- 4/8 -->
      <div class="SevenyearOld_flex" @click="showPopupBox">
        <p>点亮二岁成长礼</p>
        <img class="gift" :src="gifty" alt="" />
        <p>{{ distance }}</p>
      </div>
    </div>
    <div class="EightyearOld">
      <!-- 4/9 -->
      <div class="EightyearOld_flex" @click="showPopupMenu">
        <p>点亮三岁学习礼</p>
        <img class="gift" :src="gifat" alt="" />
        <p>{{ rightOff }}</p>
      </div>
    </div>
    <div class="NineyearOld"></div>
    <div class="TenyearOld">
      <!-- 4/10 -->
      <div class="TenyearOld_flex" @click="showPopupTeam">
        <p>点亮全家守护礼</p>
        <img class="gift" :src="gifth" alt="" />
        <p>{{ Endactivity }}</p>
      </div>
    </div>
    <div class="grow_up"></div>
    <div class="login-box" v-show="menuList" @click="menuListBox">
      <div class="conten_flex_cneter">
        <img
          @click="handlePopup(menuBox, gift)"
          src="https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/back_one.png"
          alt=""
          v-show="back_one"
        />
        <img
          @click="dataTeamPopup(Unlock, gifts)"
          src="https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/back_two.png"
          alt=""
          v-show="back_two"
        />
        <img
          @click="TimeBoxPopup(distance, gifty)"
          src="https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/back_three.png"
          alt=""
          v-show="back_three"
        />
        <img
          @click="TimeNodePopup(rightOff, gifat)"
          src="https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/back_four.png"
          alt=""
          v-show="back_four"
        />
        <img
          @click="lastTimePopup(Endactivity, gifth)"
          src="https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/back_five.png"
          alt=""
          v-show="back_five"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      menuList: false,
      back_one: false,
      back_two: false,
      back_three: false,
      back_four: false,
      back_five: false,
      open: "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/open.png",
      imgList: [
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/oneyear.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/TwoyearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/ThreeyearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/FouryearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/FiveyearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/SixyearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/SevenyearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/EightyearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/NineyearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/TenyearOld.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/birh.png",
        "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/grow_up.png",
      ],
    };
  },
  // 时间差计算
  created: function () {
    //背景图片预加载
    let count = 0;
    Toast.loading({
      message: "加载中",
      forbidClick: true,
      duration: 0,
      className:'ad'
    });
    for (let item of this.imgList) {
      let img = new Image();
      img.src = item;
      img.onload = () => {
        count++;
        if (count == this.imgList.length) {
          Toast.clear();
        }
      };
    }

    let beginTime = new Date();
    this.value =
      beginTime.getFullYear() +
      "-" +
      (beginTime.getMonth() + 1) +
      "-" +
      beginTime.getDate();
    //3月
    if (beginTime.getMonth() + 1 == 3) {
      this.dataTime = beginTime.getDate();
      this.dataList = 31;
      this.dataDate = this.dataList - this.dataTime;
      //4/6
      this.dataBox = this.dataDate + 6;
      if (this.dataBox == this.dataBox) {
        this.menuBox = this.dataBox + "天后可解锁";
        this.gift = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      //4/7
      this.dataTeam = this.dataDate + 7;
      if (this.dataTeam == this.dataTeam) {
        this.Unlock = this.dataTeam + "天后可解锁";
        this.gifts = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      //4/8
      this.TimeBox = this.dataDate + 8;
      if (this.TimeBox == this.TimeBox) {
        this.distance = this.TimeBox + "天后可解锁";
        this.gifty = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      //4/9
      this.TimeNode = this.dataDate + 9;
      if (this.TimeNode == this.TimeNode) {
        this.rightOff = this.TimeNode + "天后可解锁";
        this.gifat = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      //4/10
      this.lastTime = this.dataDate + 10;
      if (this.lastTime == this.lastTime) {
        this.Endactivity = this.lastTime + "天后可解锁";
        this.gifth = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      //4月
    } else if (beginTime.getMonth() + 1 == 4) {
      //4/6
      this.dataTime = beginTime.getDate();
      this.dataList = 6;
      this.dataDate = this.dataList - this.dataTime;
      this.dataBox = this.dataDate;
      if (this.dataBox > 2) {
        this.menuBox = this.dataBox + "天后可解锁";
        this.gift = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.dataBox == 2) {
        this.menuBox = "后天可解锁";
        this.gift = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.dataBox == 1) {
        this.menuBox = "明天可解锁";
        this.gift = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.dataBox == 0) {
        this.menuBox = "待解锁";
        this.gift = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("dj");
        if (localStorage.getItem("dj") == "true") {
          this.menuBox = "已解锁";
          this.gift = this.open;
        }
      }
      if (this.dataBox < 0) {
        this.menuBox = "待解锁";
        this.gift = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("dj");
        if (localStorage.getItem("dj") == "true") {
          this.menuBox = "已解锁";
          this.gift = this.open;
        }
      }
      //4/7
      this.dataLists = 7;
      this.dataDate = this.dataLists - this.dataTime;
      this.dataTeam = this.dataDate;
      if (this.dataTeam > 2) {
        this.Unlock = this.dataTeam + "天后可解锁";
        this.gifts = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.dataTeam == 2) {
        this.Unlock = "后天可解锁";
        this.gifts = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.dataTeam == 1) {
        this.Unlock = "明天可解锁";
        this.gifts = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.dataTeam == 0) {
        this.Unlock = "待解锁";
        this.gifts = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("li");
        if (localStorage.getItem("li") == "true") {
          this.Unlock = "已解锁";
          this.gifts = this.open;
        }
      }
      if (this.dataTeam < 0) {
        this.Unlock = "待解锁";
        this.gifts = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("li");
        if (localStorage.getItem("li") == "true") {
          this.Unlock = "已解锁";
          this.gifts = this.open;
        }
      }
      //4/8
      this.dataListTw = 8;
      this.dataDate = this.dataListTw - this.dataTime;
      this.TimeBox = this.dataDate;
      if (this.TimeBox > 2) {
        this.distance = this.TimeBox + "天后可解锁";
        this.gifty = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.TimeBox == 2) {
        this.distance = "后天可解锁";
        this.gifty = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.TimeBox == 1) {
        this.distance = "明天可解锁";
        this.gifty = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.TimeBox == 0) {
        this.distance = "待解锁";
        this.gifty = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("jie");
        if (localStorage.getItem("jie") == "true") {
          this.distance = "已解锁";
          this.gifty = this.open;
        }
      }
      if (this.TimeBox < 0) {
        this.distance = "待解锁";
        this.gifty = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("jie");
        if (localStorage.getItem("jie") == "true") {
          this.distance = "已解锁";
          this.gifty = this.open;
        }
      }
      //4/9
      this.dataListTa = 9;
      this.dataDate = this.dataListTa - this.dataTime;
      this.TimeNode = this.dataDate;
      if (this.TimeNode > 2) {
        this.rightOff = this.TimeNode + "天后可解锁";
        this.gifat = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.TimeNode == 2) {
        this.rightOff = "后天可解锁";
        this.gifat = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.TimeNode == 1) {
        this.rightOff = "明天可解锁";
        this.gifat = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.TimeNode == 0) {
        this.rightOff = "待解锁";
        this.gifat = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("so");
        if (localStorage.getItem("so") == "true") {
          this.rightOff = "已解锁";
          this.gifat = this.open;
        }
      }
      if (this.TimeNode < 0) {
        this.rightOff = "待解锁";
        this.gifat = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("so");
        if (localStorage.getItem("so") == "true") {
          this.rightOff = "已解锁";
          this.gifat = this.open;
        }
      }
      //4/10
      this.dataListTb = 10;
      this.dataDate = this.dataListTb - this.dataTime;
      this.lastTime = this.dataDate;
      if (this.lastTime > 2) {
        this.Endactivity = this.lastTime + "天后可解锁";
        this.gifth = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.lastTime == 2) {
        this.Endactivity = "后天可解锁";
        this.gifth = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.lastTime == 1) {
        this.Endactivity = "明天可解锁";
        this.gifth = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
      }
      if (this.lastTime == 0) {
        this.Endactivity = "待解锁";
        this.gifth = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("us");
        if (localStorage.getItem("us") == "true") {
          this.Endactivity = "已解锁";
          this.gifth = this.open;
        }
      }
      if (this.lastTime < 0) {
        this.Endactivity = "待解锁";
        this.gifth = "https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/gift.gif";
        localStorage.getItem("us");
        if (localStorage.getItem("us") == "true") {
          this.Endactivity = "已解锁";
          this.gifth = this.open;
        }
      }
    }
  },
  methods: {
    showPopup() {
      if (this.dataBox > 2) {
        Toast(this.dataBox + "天后可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.dataBox == 2) {
        Toast("后天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.dataBox == 1) {
        Toast("明天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.dataBox == 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = true;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.dataBox < 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = true;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
    },
    showPopupList() {
      if (this.dataTeam > 2) {
        Toast(this.dataTeam + "天后可解锁");
        this.menuList = false;
        this.back_two = false;
        this.back_one = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.dataTeam == 2) {
        Toast("后天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.dataTeam == 1) {
        Toast("明天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.dataTeam == 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = false;
        this.back_two = true;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.dataTeam < 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = false;
        this.back_two = true;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
    },
    showPopupBox() {
      if (this.TimeBox > 2) {
        Toast(this.TimeBox + "天后可解锁");
        this.menuList = false;
        this.back_two = false;
        this.back_one = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.TimeBox == 2) {
        Toast("后天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.TimeBox == 1) {
        Toast("明天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.TimeBox == 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = false;
        this.back_two = false;
        this.back_three = true;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.TimeBox < 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = false;
        this.back_two = false;
        this.back_three = true;
        this.back_four = false;
        this.back_five = false;
      }
    },
    showPopupMenu() {
      if (this.TimeNode > 2) {
        Toast(this.TimeNode + "天后可解锁");
        this.menuList = false;
        this.back_two = false;
        this.back_one = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.TimeNode == 2) {
        Toast("后天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.TimeNode == 1) {
        Toast("明天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.TimeNode == 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = true;
        this.back_five = false;
      }
      if (this.TimeNode < 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = true;
        this.back_five = false;
      }
    },
    showPopupTeam() {
      if (this.lastTime > 2) {
        Toast(this.lastTime + "天后可解锁");
        this.menuList = false;
        this.back_two = false;
        this.back_one = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.lastTime == 2) {
        Toast("后天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.lastTime == 1) {
        Toast("明天可解锁");
        this.menuList = false;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = false;
      }
      if (this.lastTime == 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = true;
      }
      if (this.lastTime < 0) {
        Toast.clear();
        this.menuList = true;
        this.back_one = false;
        this.back_two = false;
        this.back_three = false;
        this.back_four = false;
        this.back_five = true;
      }
    },
    //出生
    handlePopup(menuBox, gift) {
      let birh = true;
      // eslint-disable-next-line no-undef
      uni.navigateTo({
        url: `/pages-sub/outside/outside?types=active001&title=备产好物`,
      });
      if (menuBox == "待解锁" && gift == gift) {
        this.menuBox = "已解锁";
        this.gift = this.open;
        localStorage.setItem("dj", birh);
      }
    },
    //一岁
    dataTeamPopup(Unlock, gifts) {
      let OneyearOld = true;
      // eslint-disable-next-line no-undef
      uni.navigateTo({
        url: `/pages-sub/outside/outside?types=activityIntroduction1&title=一岁好物清单`,
      });
      if (Unlock == "待解锁" && gifts == gifts) {
        this.Unlock = "已解锁";
        this.gifts = this.open;
        localStorage.setItem("li", OneyearOld);
      }
    },
    //二岁
    TimeBoxPopup(distance, gifty) {
      let TwoyearOld = true;
      // eslint-disable-next-line no-undef
      uni.navigateTo({
        url: `/pages-sub/outside/outside?types=activityIntroduction2&title=二岁好物清单`,
      });
      if (distance == "待解锁" && gifty == gifty) {
        this.distance = "已解锁";
        this.gifty = this.open;
        localStorage.setItem("jie", TwoyearOld);
      }
    },
    //三岁
    TimeNodePopup(rightOff, gifat) {
      let ThreeyearOld = true;
      // eslint-disable-next-line no-undef
      uni.navigateTo({
        url: `/pages-sub/outside/outside?types=active003&title=三岁好物清单`,
      });
      if (rightOff == "待解锁" && gifat == gifat) {
        this.rightOff = "已解锁";
        this.gifat = this.open;
        localStorage.setItem("so", ThreeyearOld);
      }
    },
    lastTimePopup(Endactivity, gifth) {
      // eslint-disable-next-line no-undef
      uni.navigateTo({
        url: `/pages-sub/outside/outside?types=active002&title=守护全家好物`,
      });
      let FouryearOld = true;
      if (Endactivity == "待解锁" && gifth == gifth) {
        this.Endactivity = "已解锁";
        this.gifth = this.open;
        localStorage.setItem("us", FouryearOld);
      }
    },
    //隐藏遮罩层
    menuListBox() {
      this.menuList = false;
    },
  },
};
</script>

<style lang="less">
.list {
  width: 100%;
  height: auto;
  .birth {
    width: 100%;
    height: 7.8rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/birh.png") no-repeat;
    background-size: 100%;
  }
  .OneyearOld {
    width: 100%;
    height: 5.8rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/oneyear.png") no-repeat;
    background-size: 100%;
    margin-top: -0.007rem;
    padding: 4.6rem 0rem 0rem 0rem;
    .OneyearOld_flex {
      width: 1.1rem;
      height: 1rem;
      text-align: center;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* Internet Explorer 9*/
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg); /* Opera */
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
      margin-left: 0.9rem;
      z-index: 999999;
      p:first-child {
        font-size: 0.11rem;
        color: #333333;
        font-weight: bold;
      }
      p:last-child {
        font-size: 0.12rem;
        color: #333333;
        font-weight: bold;
      }
      .gift {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
  .TwoyearOld {
    width: 100%;
    height: 5.17rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/TwoyearOld.png") no-repeat;
    background-size: 100%;
  }
  .ThreeyearOld {
    width: 100%;
    height: 6.86rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/ThreeyearOld.png") no-repeat;
    background-size: 100%;
    margin-top: -0.02rem;
  }
  .FouryearOld {
    width: 100%;
    height: 6.23rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/FouryearOld.png") no-repeat;
    background-size: 100%;
    margin-top: -0.02rem;
    padding: 0.4rem 0rem 0rem 0rem;
    .FouryearOld_flex {
      width: 1.1rem;
      height: 1rem;
      text-align: center;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* Internet Explorer 9*/
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg); /* Opera */
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
      margin-left: 2.2rem;
      z-index: 999999;
      p:first-child {
        font-size: 0.11rem;
        color: #333333;
        font-weight: bold;
      }
      p:last-child {
        font-size: 0.12rem;
        color: #333333;
        font-weight: bold;
      }
      .gift {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
  .FiveyearOld {
    width: 100%;
    height: 5.54rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/FiveyearOld.png") no-repeat;
    background-size: 100%;
    margin-top: -0.02rem;
  }
  .SixyearOld {
    width: 100%;
    height: 5.37rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/SixyearOld.png") no-repeat;
    background-size: 100%;
    margin-top: -0.02rem;
  }
  .SevenyearOld {
    width: 100%;
    height: 6.9rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/SevenyearOld.png") no-repeat;
    background-size: 100%;
    margin-top: -0.02rem;
    padding: 3rem 0rem 0rem 0rem;
    .SevenyearOld_flex {
      width: 1.1rem;
      height: 1rem;
      text-align: center;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* Internet Explorer 9*/
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg); /* Opera */
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
      margin-left: 2.4rem;
      z-index: 999999;
      p:first-child {
        font-size: 0.11rem;
        color: #333333;
        font-weight: bold;
      }
      p:last-child {
        font-size: 0.12rem;
        color: #333333;
        font-weight: bold;
      }
      .gift {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
  .EightyearOld {
    width: 100%;
    height: 7.06rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/EightyearOld.png") no-repeat;
    background-size: 100%;
    padding: 2.5rem 0rem 0rem 0rem;
    .EightyearOld_flex {
      width: 1.1rem;
      height: 1rem;
      text-align: center;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* Internet Explorer 9*/
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg); /* Opera */
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
      margin-left: 2.5rem;
      z-index: 999999;
      p:first-child {
        font-size: 0.11rem;
        color: #333333;
        font-weight: bold;
      }
      p:last-child {
        font-size: 0.12rem;
        color: #333333;
        font-weight: bold;
      }
      .gift {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
  .NineyearOld {
    width: 100%;
    height: 5.57rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/NineyearOld.png") no-repeat;
    background-size: 100%;
    margin-top: -0.02rem;
  }
  .TenyearOld {
    width: 100%;
    height: 6.45rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/TenyearOld.png") no-repeat;
    background-size: 100%;
    margin-top: -0.02rem;
    .TenyearOld_flex {
      width: 1.1rem;
      height: 1rem;
      text-align: center;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* Internet Explorer 9*/
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg); /* Opera */
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
      margin-left: 2.5rem;
      z-index: 999999;
      p:first-child {
        font-size: 0.11rem;
        color: #333333;
        font-weight: bold;
      }
      p:last-child {
        font-size: 0.12rem;
        color: #333333;
        font-weight: bold;
      }
      .gift {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
  .grow_up {
    width: 100%;
    height: 5.3rem;
    background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/grow_up.png") no-repeat;
    background-size: 100%;
    margin-top: -0.02rem;
  }
  .login-box {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
  }
  .conten_flex_cneter {
    width: 2.5rem;
    height: 2.5rem;
    z-index: 999999;
    margin-left: 0.3rem;
    margin-top: 2rem;
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
.van-toast {
  left: 35% !important;
}
.van-toast.ad{
  left: 50% !important;
}
.van-toast--text {
  transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important; /* Internet Explorer 9*/
  -moz-transform: rotate(90deg) !important; /* Firefox */
  -webkit-transform: rotate(90deg) !important; /* Safari 和 Chrome */
  -o-transform: rotate(90deg) !important; /* Opera */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1) !important;
}
</style> 